// React core.
import React, {useEffect, useState} from 'react';
// import ReactDOM from 'react-dom';
// import {auth} from './config/firebase';
// import routes from './config/routes';
// Firebase.
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

// Styles
import styles from './app.css'; // This uses CSS modules.
import './firebaseui-styling.global.css';
// import App0, {IApplicationProps} from "./App0";
import config from "./config/config";
import {BrowserRouter as Router, Route, RouteComponentProps, Switch} from "react-router-dom";
import {Button, Card} from "@material-ui/core"; // Import globally.

// // Get the Firebase config from the auto generated file.
// const firebaseConfig = require('./firebase-config.json').result.sdkConfig;
//
// // Instantiate a Firebase app.
// const firebaseApp = firebase.initializeApp(firebaseConfig);

import { getAnalytics } from "firebase/analytics";

const firebaseApp = firebase.initializeApp(config.firebase);
const analytics = getAnalytics(firebaseApp);

/**
 * The Splash Page containing the login UI.
 */
class App extends React.Component {
  uiConfig = {
    signInFlow: 'popup',

    // Popup signin flow rather than redirect flow.
    // signInFlow: firebase.auth().isSignInWithEmailLink(window.location.href) ? 'redirect' : 'popup',

    signInOptions: [
      // scopes: https://firebase.google.com/docs/auth/web/google-signin
      firebase.auth.GoogleAuthProvider.PROVIDER_ID, // OK
      firebase.auth.TwitterAuthProvider.PROVIDER_ID, // need permissions
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.GithubAuthProvider.PROVIDER_ID, // OK. TODO: request scopes: https://docs.github.com/en/developers/apps/building-oauth-apps/authorizing-oauth-apps
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    // Terms of service url.
    tosUrl: 'https://inngroups.com/terms.html',
    // Privacy policy url.
    privacyPolicyUrl: 'https://inngroups.com/privacy.html',
    callbacks: {
      signInSuccessWithAuthResult: () => false,
      // // Avoid redirects after sign-in.
      // // signInSuccessWithAuthResult: () => false
      // signInSuccessWithAuthResult: () => this.setState({loginModalShow: false}) //  false
    },
  };

  state = {
    isSignedIn: undefined,
  };

   signUpWithLinkedIn() {
     // https://stackoverflow.com/questions/40040025/has-someone-managed-to-implement-a-linkedin-login-with-firebase-on-ios
    return firebase.auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(()=>{
        const provider = new firebase.auth.OAuthProvider('linkedin.com');
        provider.addScope('r_emailaddress');
        provider.addScope('r_liteprofile');
        firebase.auth()
          .signInWithPopup(provider)
          .then(result=>{
            console.group('LinkedIn');
            console.log(result);
            console.groupEnd();
            return result;
          })
          .catch(error=>{
            console.group('LinkedIn - Error');
            console.log(error)
            console.groupEnd();
            throw error;
          });

      });
  }

  /**
   * @inheritDoc
   */
  componentDidMount() {
    // this.unregisterAuthObserver = firebaseApp.auth().onAuthStateChanged((user) => {
    //   this.setState({isSignedIn: !!user});
    // });

    // Listen to the Firebase Auth state and set the local state
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (signedUser) => {
        this.setState({isSignedIn: !!signedUser})

        if (signedUser) {
          // console.debug(signedUser);

          firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(this.onTokenArrived)
            .catch(function (error) {
              // Handle error
            });

          //Once you have an ID token, you can send that JWT to your backend and validate it using the Firebase Admin SDK, or using a third-party JWT library if your server is written in a language which Firebase does not natively support.                console.debug("Auth user: ");

          // this.setState({isSignedIn: true, user: signedUser});
          this.setState({isSignedIn: true});

          // this.fetchUserDataAPI();
        } else {
          // setGlobal({userToken: null});
          this.setState({isSignedIn: false});
          // this.setState({isSignedIn: false, user: {}});
        }
      }
    );
  }

  onTokenArrived(idToken) {
    // Send token to your backend via HTTPS
    // ...

    // console.debug("user token:");
    // console.debug(idToken);
    // console.debug(signedUser);
    // setGlobal({userToken: idToken}).then(this.fetchUserDataAPI);
    // this.setState({userToken: idToken});
  }

  signOut() {
    firebase.auth().signOut();
  }

  deleteAccount() {
    if (!!firebase.auth().currentUser) {
      firebase.auth().currentUser.delete().catch(function (error) {
        if (error.code === 'auth/requires-recent-login') {
          // The user's credential is too old. She needs to sign in again.
          firebase.auth().signOut().then(function () {
            // The timeout allows the message to be displayed after the UI has
            // changed to the signed out state.
            setTimeout(function () {
              alert('Please sign in again to delete your account.');
            }, 1);
          });
        }
      });
    }
  };

  renderAccShort() {
    let currentUser = firebase.auth().currentUser;
    const isLoggedIn = !!currentUser;
    let element;
    if (isLoggedIn) {
      let photoURL = ""
      if (currentUser.photoURL) {
        photoURL = currentUser.photoURL;
        // Append size to the photo URL for Google hosted images to avoid requesting
        // the image with its original resolution (using more bandwidth than needed)
        // when it is going to be presented in smaller size.
        if ((photoURL.indexOf('googleusercontent.com') != -1) ||
          (photoURL.indexOf('ggpht.com') != -1)) {

          // https://developers.google.com/people/image-sizing
          photoURL = photoURL + '=s128-c';
          //document.getElementById('photo').clientHeight;
        }
        // document.getElementById('photo').src = photoURL;
        // document.getElementById('photo').style.display = 'block';
      } else {
        // document.getElementById('photo').style.display = 'none';
      }

      element = <div>
        <div id="user-info" style={{
          fontSize: "0.675rem",
          // border: "1px solid #CCC",
          // backgroundColor: "#21232B",
          clear: "both",
          margin: "0 auto 10px",
          minWidth: "200px",
          maxWidth: "300px",
          padding: "5px",
          textAlign: "left",
          textIndent: "0px"
        }}>
          <div id="photo-container" style={{
            backgroundColor: "#EEE",
            border: "1px solid #CCC",
            float: "left",
            marginRight: "10px",
            maxHeight: "44px",
            maxWidth: "44px"
          }}>
            <img style={{
              display: "inline-block",
              height: "42px",
              width: "42px"
            }}
                 src={photoURL} id="photo"/>
          </div>

          <div id="name">{currentUser.displayName}</div>
          <div id="email">{currentUser.email}</div>
          <div id="phone">{currentUser.phoneNumber}</div>
          <div id="is-new-user"></div>
          <div className="clearfix"></div>
          <br></br>


          {/*<MyDialogComponent*/}
          {/*  {...this.props}*/}
          {/*  title='🔐 Кабинет'*/}
          {/*  onActivate={this.fetchUserDataAPI}*/}
          {/*>*/}
          {/*  {this.renderDialogContent()}*/}
          {/*</MyDialogComponent>*/}

          {/*style={{fontSize: "0.675rem", color: "#fff", display: "inline-block"}}*/}
          <Button style={{fontSize: "0.675rem"}}
                  onClick={() => this.signOut()}>Log out</Button>
          {/*<Button style={{fontSize: "0.675rem", color: "#fff"}}*/}
          {/*        onClick={() => this.deleteAccount()}>Удалить*/}
          {/*  аккаунт</Button>*/}

          {/*{this.renderWin()}*/}

        </div>
      </div>;
    } else {
      element = <div id="user-info" style={{
        fontSize: "0.675rem",
        // border: "1px solid #CCC",
        // backgroundColor: "#21232B",
        clear: "both",
        margin: "0 auto 10px",
        minWidth: "200px",
        maxWidth: "300px",
        padding: "5px",
        textAlign: "left",
        textIndent: "0px"
      }}><p>You are not signed in</p>
        {/*{this.renderSignInButton()}*/}
      </div>;
    }

    return (
      <Card elevation={9}>{element}</Card>
    )
  }

  /**
   * @inheritDoc
   */
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  /**
   * @inheritDoc
   */
  render() {
    return (
      <div className={styles.container}>
        {/*<div className={styles.logo}>*/}
        {/*  <i className={styles.logoIcon + ' material-icons'}>photo</i> My App*/}
        {/*</div>*/}
        {/*<div className={styles.caption}>This is a cool demo app</div>*/}

        {this.renderAccShort()}

        {this.state.isSignedIn !== undefined && !this.state.isSignedIn &&
        <div>
          {/*<Button style={{fontSize: "0.675rem"}}*/}
          {/*        onClick={() => this.signUpWithLinkedIn()}>LinkedIn</Button>*/}

          <StyledFirebaseAuth className={styles.firebaseUi} uiConfig={this.uiConfig}
                              firebaseAuth={firebaseApp.auth()}/>
        </div>
        }
        {/*{this.state.isSignedIn &&*/}
        {/*<div className={styles.signedIn}>*/}
        {/*  Hello {firebaseApp.auth().currentUser.displayName}. You are now signed In!*/}
        {/*  <a className={styles.button} onClick={() => firebaseApp.auth().signOut()}>Sign-out</a>*/}
        {/*</div>*/}
        {/*}*/}

      </div>
    );
  }
}

// import React, { useEffect, useState } from 'react';
// import { BrowserRouter as Router, Switch, Route, RouteComponentProps } from 'react-router-dom';
// import { auth } from './config/firebase';
// import routes from './config/routes';
// import AuthRoute from './modules/auth/AuthRouter';
// import './app.css';
//
// export interface IApplicationProps { }
// const App0: React.FunctionComponent<IApplicationProps> = props => {
//   const [loading, setLoading] = useState<boolean>(true);
//
//   // Monitor and Update user state.
//   useEffect(() => {
//     auth.onAuthStateChanged(user => {
//       if (user) {
//         console.log('User detected.')
//       } else {
//         console.log('No user detected');
//       }
//       setLoading(false);
//     })
//   }, []);
//   if (loading)
//     return <div>Loading...</div>
//
//   return (
//     <Router>
//       <Switch>
//         {routes.map((route, index) =>
//           <Route
//             key={index}
//             path={route.path}
//             exact={route.exact}
//             render={(routeProps: RouteComponentProps<any>) => {
//               if (route.protected)
//                 return <AuthRoute ><route.component {...routeProps} /></AuthRoute>;
//
//               return <route.component  {...routeProps} />;
//             }}
//           />)}
//       </Switch>
//     </Router>
//   );
// }


// Load the app in the browser.
// ReactDOM.render(<App/>, document.getElementById('app'));

export default App;
